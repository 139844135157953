const Config = require('~config/default.env').default;
import axios from 'axios';
const merge = require('lodash.merge');
import { removeFalsyParams } from '~global/helpers';
import { isWeb } from '~global/screens';
const qs = require('qs');
const catalogUrl = `${Config.MS_CATALOG_API_URL}/v3/products`;

const productsRequest = (params) => {
  return axios.get(`${catalogUrl}`, { params });
};

const productJustLandedRequest = (params) => {
  return axios.get(`${Config.MS_CATALOG_API_URL}/v3/catalog-rules/detail`, {
    params,
  });
};

// recently viewed products
const productViewsRequest = (params) => {
  return axios.get(`${Config.MS_CATALOG_API_URL}/v3/product-views`, { params });
};

const wishlistRequest = (params) => {
  return axios.get(`${Config.MS_CATALOG_API_URL}/v3/users/me/wishlists`, {
    params,
  });
};

const wishlistCountRequest = () => {
  return axios.get(`${Config.MS_CATALOG_API_URL}/v3/users/me/wishlists/count`);
};

const addWishlist = (payload) => {
  return axios.post(
    `${Config.MS_CATALOG_API_URL}/v3/users/me/wishlists`,
    payload,
  );
};

const bannersRequest = (params) => {
  return axios.get(`${Config.MS_CATALOG_API_URL}/banners`, { params });
};

const bannersCountRequest = (params) => {
  return axios.get(`${Config.MS_CATALOG_API_URL}/banners/count`, { params });
};

const bannersAllRequest = (params) => {
  return axios.get(`${Config.MS_CATALOG_API_URL}/banners/all`, { params });
};

const similarProductRequest = (params) => {
  return axios.get(`${Config.MS_ANALYTICS_API_URL}/product/similar`, {
    params,
  });
};

const categoriesRequest = (params) => {
  const modifyParams = JSON.parse(JSON.stringify(params));
  if (modifyParams.filter && modifyParams.filter['phase_tags.id']) {
    modifyParams.fields = `name my_sociolla_sql_id link_rewrite parents slug banner_for_sociolla lulla_logo logo_for_moms logo_for_baby_kids name_for_moms name_for_baby_kids phase_tags`;
  }
  return axios.get(`${Config.MS_CATALOG_API_URL}/v3/categories`, {
    params: modifyParams,
  });
};

const categoriesChildrenRequest = (params) => {
  return axios.get(`${Config.MS_CATALOG_API_URL}/v3/categories/children`, {
    params,
  });
};

const tagsRequest = (params) => {
  return axios.get(`${Config.MS_CATALOG_API_URL}/tags`, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { encode: false }),
  });
};

const getProductsRequest = (newParams) => {
  const defaultParams = {
    fields:
      'images,name,review_stats,default_combination,classification,pack_detail,combinations,id,_id,is_bundle_pack,is_in_stock,is_out_of_stock_lilla,is_new,is_sale,is_exclusive,is_exclusive_deals,is_pre_order,discount_range,brand,price_after_discount_range,price_range,slug,discounts',
  };
  const finalParams = removeFalsyParams({ ...newParams });
  const params = merge(defaultParams, finalParams);
  return axios.get(`${Config.MS_CATALOG_API_URL}/search`, { params: params });
};

const getTotalProductsRequest = (params) => {
  const finalParams = removeFalsyParams({ ...params });
  return axios.get(`${Config.MS_CATALOG_API_URL}/search/count`, {
    params: finalParams,
  });
};

const getBestSellerProductsRequest = (params) => {
  return axios.get(`${Config.MS_CATALOG_API_URL}/v3/best-seller`, { params });
};

const searchV3Products = (params) => {
  const request = params.cancelToken ? params : { params };
  const finalParams = { params: removeFalsyParams({ ...request.params }) };
  return axios.get(`${Config.MS_CATALOG_API_URL}/v3/search`, finalParams);
};

const searchTotalProductsRequest = (params) => {
  const finalParams = removeFalsyParams({ ...params });
  return axios.get(`${Config.MS_CATALOG_API_URL}/v3/search/count`, {
    params: finalParams,
  });
};

const getProductRequest = (slug) => {
  return axios.get(`${Config.MS_CATALOG_API_URL}/v3/products/${slug}`, {
    params: {
      filter: { is_verified_purchase: true },
    },
  });
};

const getPeopleAlsoBuyProducts = (productId) => {
  return axios.get(
    `${Config.MS_CATALOG_API_URL}/v3/people-also-buy/${productId}`,
    {
      params: {
        limit: 10,
      },
    },
  );
};

const getTrendingNowProducts = () => {
  return axios.get(`${Config.MS_CATALOG_API_URL}/v3/products`, {
    params: {
      limit: 15,
      skip: 0,
      sort: '-two_days_total_views',
      filter: { is_active_in_lulla: true, is_repurchase: true },
    },
  });
};

const notifyMe = (payload) => {
  return axios.post(
    `${Config.MS_CATALOG_API_URL}/v3/product-availability-alert`,
    payload,
  );
};

const getBannersPromotion = (id, params) => {
  return axios.get(`${Config.MS_CATALOG_API_URL}/v3/promotions/${id}`, {
    params,
  });
};

const getBannersCampaigns = (id, params) => {
  //CAMPAIGN v2
  return axios.get(`${Config.MS_CATALOG_API_URL}/v3/campaigns/${id}`, {
    params,
  });
};

const getPostsCampaignV2 = ({ id, section, type }, params) => {
  return axios.get(
    `${Config.MS_CATALOG_API_URL}/v3/campaigns/${id}/sections/${section}/posts/${type}`,
    { params },
  );
};

const getProductBrandCampaignV2 = ({ id, section }, params) => {
  return axios.get(
    `${Config.MS_CATALOG_API_URL}/v3/campaigns/${id}/sections/${section}/products`,
    { params },
  );
};

const getProductBrandPromotion = ({ id, section }, params) => {
  return axios.get(
    `${Config.MS_CATALOG_API_URL}/v3/promotions/${id}/section/${section}/products`,
    { params },
  );
};

const getBrandCategories = (params) => {
  return axios.get(
    `${Config.MS_CATALOG_API_URL}/v3/categories/distinct/products`,
    { params },
  );
};

const getBrandProductPromotion = (params) => {
  return axios.get(`${Config.MS_CATALOG_API_URL}/v3/promotions`, { params });
};

const getBestSellerLoadProductsRequest = (params) => {
  return axios.get(`${Config.MS_CATALOG_API_URL}/search`, { params });
};

const getFlashSale = (params) => {
  return axios.get(`${Config.MS_CATALOG_API_URL}/v3/product-price-rules/new`, {
    params,
  });
};

const getTotalFlashSale = (id) => {
  return axios.get(
    `${Config.MS_CATALOG_API_URL}/v3/product-price-rules/new/count`,
    {
      params: {
        filter: { id },
      },
    },
  );
};

const getCategoryDetail = (slug) => {
  return axios.get(`${Config.MS_CATALOG_API_URL}/v3/categories/${slug}`);
};

export function getAllProducts({ type, slug, params }) {
  const defaultParams = {
    filter: { 'categories.slug': slug, type },
    limit: isWeb ? 16 : 10,
    skip: 0,
  };
  const mergeParams = merge(defaultParams, params);

  return axios.get(`${Config.MS_CATALOG_API_URL}/search`, {
    params: mergeParams,
  });
}

export function getTotalProducts({ slug, params, type }) {
  const defaultParams = {
    filter: { 'categories.slug': slug, type },
    limit: isWeb ? 16 : 10,
    skip: 0,
  };
  const mergeParams = merge(defaultParams, params);
  return axios.get(`${Config.MS_CATALOG_API_URL}/search/count`, {
    params: mergeParams,
  });
}

export function getEgiftTemplates() {
  return axios.get(`${Config.MS_CATALOG_API_URL}/v3/egift-templates`, {
    params: {
      sort: `position -created_at`,
      filter: {
        $or: [{ is_deleted: false }, { is_deleted: { $exists: false } }],
      },
    },
  });
}

const activeCategoryType = () => {
  return axios.get(`${Config.MS_CATALOG_API_URL}/v3/active-category-display`);
};

const getPhaseCategoryHome = () => {
  return axios.get(
    `${Config.MS_CATALOG_API_URL}/v3/phase-category-home-display`,
    {
      params: {
        sort: `positions -created_at`,
        filter: {
          $or: [{ is_deleted: false }, { is_deleted: { $exists: false } }],
        },
      },
    },
  );
};

const getGroupCategoryHome = () => {
  return axios.get(`${Config.MS_CATALOG_API_URL}/v3/group-category-display`, {
    params: {
      sort: `group_position -created_at`,
      filter: {
        is_active: true,
        $or: [{ is_deleted: false }, { is_deleted: { $exists: false } }],
      },
    },
  });
};

const getMultipleProducts = ({ lillaCategoryType, slug, params, type }) => {
  const defaultParams = {
    filter: {
      ...(['group', 'group-child'].includes(lillaCategoryType)
        ? {
            is_new_lilla_category: true,
            slug,
            lilla_category_type: lillaCategoryType,
          }
        : {}),
      type,
    },
    limit: isWeb ? 16 : 10,
    skip: 0,
  };
  const mergeParams = merge(defaultParams, params);
  return axios.get(`${Config.MS_CATALOG_API_URL}/search`, {
    params: mergeParams,
    paramsSerializer: (params) => qs.stringify(params, { encode: true }),
  });
};

const getMultipleProductsCount = ({
  lillaCategoryType,
  slug,
  params,
  type,
}) => {
  const defaultParams = {
    filter: {
      ...(['group', 'group-child'].includes(lillaCategoryType)
        ? {
            is_new_lilla_category: true,
            slug,
            lilla_category_type: lillaCategoryType,
          }
        : {}),
      type,
    },
    limit: isWeb ? 16 : 10,
    skip: 0,
  };
  const mergeParams = merge(defaultParams, params);
  return axios.get(`${Config.MS_CATALOG_API_URL}/search/count`, {
    params: mergeParams,
    paramsSerializer: (params) => qs.stringify(params, { encode: true }),
  });
};

export const Catalog = {
  productsRequest,
  wishlistRequest,
  productViewsRequest,
  wishlistCountRequest,
  bannersRequest,
  bannersCountRequest,
  bannersAllRequest,
  similarProductRequest,
  categoriesRequest,
  categoriesChildrenRequest,
  tagsRequest,
  getProductsRequest,
  getTotalProductsRequest,
  getBestSellerProductsRequest,
  searchTotalProductsRequest,
  getProductRequest,
  getPeopleAlsoBuyProducts,
  getTrendingNowProducts,
  addWishlist,
  notifyMe,
  getBannersPromotion,
  getBannersCampaigns,
  getProductBrandPromotion,
  getProductBrandCampaignV2,
  getPostsCampaignV2,
  getBrandCategories,
  getBrandProductPromotion,
  productJustLandedRequest,
  getBestSellerLoadProductsRequest,
  getFlashSale,
  getTotalFlashSale,
  getCategoryDetail,
  getAllProducts,
  getTotalProducts,
  getEgiftTemplates,
  searchV3Products,
  activeCategoryType,
  getPhaseCategoryHome,
  getGroupCategoryHome,
  getMultipleProducts,
  getMultipleProductsCount,
};
